import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import Moment from "react-moment"
import { Link } from "gatsby"
import _ from "lodash"

import { useLocation, navigate } from "@reach/router"
import queryString from "query-string"

// Default Filters by Type
const filtersTypeDefault = {
  Todos: {
    type: "Todos",
    label: "Todos",
  },
  Spot: {
    type: "Spot",
    label: "Spot",
  },
  Casos: {
    type: "Casos",
    label: "Casos de éxito",
  },
  Comerciales: {
    type: "Comerciales",
    label: "Comerciales",
  },
  Capacitaciones: {
    type: "Capacitaciones",
    label: "Capacitaciones",
  },
  Otro: {
    type: "Otro",
    label: "Otro",
  },
}

const VideoGrid = ({ allVideos }) => {
  const location = useLocation()
  // Get variables (t,c) from Url params
  let { t, c } = queryString.parse(location.search)
  t = t in filtersTypeDefault ? t : "Todos"

  const [categories, setCategories] = useState([])
  const [filterType, updateFilterType] = useState(t || "Todos")
  const [filterCategory, updateFilterCategory] = useState(c || "Todos")
  const [filteredVideos, setFilteredVideos] = useState(allVideos)

  useEffect(() => {
    if (filterType === "Todos") {
      updateCategories(allVideos)
      if (filterCategory === "Todos") {
        setFilteredVideos(allVideos)
      } else {
        let filteredArr = _.filter(allVideos, function (el) {
          return _.filter(el.blog_subcategorias, { nombre: filterCategory })
            .length > 0
            ? el
            : null
        })
        setFilteredVideos(filteredArr)
      }
    } else {
      let filteredArr = _.filter(allVideos, { tipo: filterType })
      updateCategories(filteredArr)
      if (filterCategory === "Todos") {
        setFilteredVideos(filteredArr)
      } else {
        let filteredArr2 = _.filter(filteredArr, function (el) {
          return _.filter(el.blog_subcategorias, { nombre: filterCategory })
            .length > 0
            ? el
            : null
        })
        setFilteredVideos(filteredArr2)
      }
    }
  }, [filterType, filterCategory, allVideos])

  useEffect(() => {
    navigate(`?t=${filterType}&c=${filterCategory}`)
  }, [filterType, filterCategory])

  const updateCategories = arr => {
    let newCategories = [{ id: "01", slug: "todos", nombre: "Todos" }]
    arr.forEach(el => {
      el.blog_subcategorias.forEach(ca => {
        newCategories = [...newCategories, ca]
      })
    })
    setCategories(_.uniqBy(newCategories, "id"))
  }

  return (
    <div className="asm-media-grid">
      <div className="row justify-content-center mb-5">
        {Object.keys(filtersTypeDefault).map((f, i) => (
          <div className="form-check form-check-inline" key={i}>
            <input
              className="form-check-input"
              type="radio"
              name="filterTypeVideoRadios"
              id={`typeVideo${filtersTypeDefault[f].type}`}
              defaultValue={filtersTypeDefault[f].type || ""}
              onChange={() => updateFilterType(filtersTypeDefault[f].type)}
              {...(filterType === filtersTypeDefault[f].type
                ? { checked: true }
                : { checked: false })}
            />
            <label
              className="form-check-label"
              htmlFor={`typeVideo${filtersTypeDefault[f].type}`}
            >
              {filtersTypeDefault[f].label}
            </label>
          </div>
        ))}
      </div>
      <div className="row mb-5">
        {categories.map((c, i) => (
          <div className="form-check form-check-inline" key={i}>
            <input
              className="form-check-input"
              type="radio"
              name="filterCategoryVideoRadios"
              id={`categoryVideo${c.nombre}`}
              defaultValue={c.nombre || ""}
              onChange={() => updateFilterCategory(c.nombre)}
              {...(filterCategory === c.nombre
                ? { checked: true }
                : { checked: false })}
            />
            <label className="form-check-label" htmlFor={`categoryVideo${c.nombre}`}>
              {c.nombre}
            </label>
          </div>
        ))}
      </div>
      <div className="row row-cols-1 row-cols-md-3">
        {filteredVideos.length > 0 ? (
          filteredVideos.map((video, index) => {
            return (
              <div className="col mb-5" key={index}>
                <div className="card h-100 rounded-0 border-0">
                  <div className="card-img-container position-relative">
                    <img
                      // Fetch thumbnail of youtube video based on: https://yt-thumb.canbeuseful.com/en
                      src={
                        "http://img.youtube.com/vi/" +
                        video.link +
                        "/mqdefault.jpg"
                      }
                      className="card-img-top rounded-0"
                      alt={video.titulo}
                    />
                  </div>
                  <div className="card-body">
                    {video.tipo && (
                      <p className="card-subtitle text-left mb-2">
                        <i className="fa fa-video-camera mr-1"></i>
                        {video.tipo}
                      </p>
                    )}
                    <p className="card-title">
                      {video.titulo}
                      <i className="fa fa-share float-right ml-1"></i>
                    </p>
                    <p className="card-text mb-0">
                      Publicado el&nbsp;
                      <Moment format="LL">{video.publicado_en}</Moment>
                    </p>
                    <Link
                      to={`/videos/${video.slug}`}
                      className="stretched-link"
                    >
                      {""}
                    </Link>
                  </div>
                  <div className="card-footer">
                    <div className="card-tags">
                      {video.blog_subcategorias.map((c, i) => (
                        <span key={i} className="badge badge-info mr-1">
                          {c.nombre}
                        </span>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            )
          })
        ) : (
          <div className="alert alert-info" role="alert">
            {`Ops! No encontramos ningún video con la categoría "${filterCategory}"`}
          </div>
        )}
      </div>
    </div>
  )
}

VideoGrid.propTypes = {
  allVideos: PropTypes.arrayOf(
    PropTypes.shape({
      strapiId: PropTypes.string,
      slug: PropTypes.string.isRequired,
      titulo: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
      publicado_en: PropTypes.string.isRequired,
      tipo: PropTypes.string,
      blog_subcategorias: PropTypes.arrayOf(
        PropTypes.shape({
          slug: PropTypes.string.isRequired,
          nombre: PropTypes.string.isRequired,
        })
      ),
    })
  ).isRequired,
}

VideoGrid.defaultProps = {
  allVideos: [
    {
      strapiId: `5ff3ac07ce1b5e19cb07092b`,
      slug: `diabetes-gestacional`,
      titulo: `Diabetes Gestacional`,
      link: `4r0IqcCBBVY`,
      publicado_en: `2020-12-17T17:00:00.000Z`,
      tipo: `Otro`,
      blog_subcategorias: [
        {
          slug: `vih`,
          nombre: `VIH`,
        },
      ],
    },
  ],
}

export default VideoGrid
