import React from "react"

// Layout
import Layout from "../components/layout"
// Components
import Menu from "../components/home/menu"
import Breadcrumb from "../components/elements/breadcrumb"
import VideoGrid from "../components/elements/video-grid"
// Hooks
import { useAsmetHome } from "../hooks/use-asmet-home"
import { useAsmetVideos } from "../hooks/use-asmet-videos"

const VideosPage = () => {
  const asmetHome = useAsmetHome()
  const allVideos = useAsmetVideos()

  const crumbs = [
    { path: `/media`, label: "Media" },
    { path: `/videos`, label: "Videos" },
  ]

  let flattenVideos = []
  allVideos.forEach(el => {
    flattenVideos = flattenVideos.concat(Object.values(el))
  })

  return (
    <Layout meta={{ og_titulo: "Videos" }}>
      <div id="asm-template-header" className="contanier-fluid pt-3 pb-3">
        <div className="container">
          <div className="row">
            <div className="col">
              <Breadcrumb crumbs={crumbs} />
              <h3 className="text-white-alpha text-left pt-4">Galería de Videos</h3>
            </div>
          </div>
        </div>
      </div>
      <div className="asm-media-main container my-5">
        <VideoGrid allVideos={flattenVideos} />
      </div>
      <hr />
      <Menu menuSecundario={asmetHome.menu_secundario}/>
    </Layout>
  )
}

export default VideosPage
